import {
  BusinessGoalCategory,
  BusinessGoalMilestone,
  BusinessGoalPriority,
  BusinessProfile,
  UserTask,
  UserTaskItemStatus,
} from '__generated__/api-types-and-hooks'
import { AppPillColorTheme } from 'components/Common/AppPillTextBox/AppPillTextBox.component'
import parsePhoneNumberFromString from 'libphonenumber-js'
import moment, { Moment } from 'moment'
import { BadgeColor } from 'components/Common/AppBadge/AppBadge.component'

export const convertEstimationHours = (hours: number): string => {
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24

  const weeks = Math.floor(days / 7)
  const remainingDays = days % 7
  return `${weeks}w ${remainingDays}d ${remainingHours}h`
}

export const getColorThemeForGoalPriority = (goalPriority: string): AppPillColorTheme => {
  switch (goalPriority) {
    case BusinessGoalPriority.LowPriority:
      return AppPillColorTheme.GREEN
    case BusinessGoalPriority.MediumPriority:
      return AppPillColorTheme.ORANGE
    case BusinessGoalPriority.HighPriority:
      return AppPillColorTheme.RED
    default:
      return AppPillColorTheme.BLUE
  }
}

export const getColorThemeForGoalCategory = (goalCategory: string): AppPillColorTheme => {
  switch (goalCategory) {
    case BusinessGoalCategory.Marketing:
      return AppPillColorTheme.GREEN
    case BusinessGoalCategory.Hr:
      return AppPillColorTheme.ORANGE
    case BusinessGoalCategory.Sales:
      return AppPillColorTheme.RED
    case BusinessGoalCategory.Legal:
      return AppPillColorTheme.BROWN
    case BusinessGoalCategory.Communications:
      return AppPillColorTheme.BLUE
    case BusinessGoalCategory.CustomerService:
      return AppPillColorTheme.LIME
    case BusinessGoalCategory.Finance:
      return AppPillColorTheme.SKY
    case BusinessGoalCategory.InformationTechnology:
      return AppPillColorTheme.YELLOW
    case BusinessGoalCategory.Operations:
      return AppPillColorTheme.PURPLE
    case BusinessGoalCategory.Production:
      return AppPillColorTheme.PURPLE
    case BusinessGoalCategory.ResearchAndDevelopment:
      return AppPillColorTheme.ORANGE
    case BusinessGoalCategory.Strategy:
      return AppPillColorTheme.BROWN
    default:
      return AppPillColorTheme.GREEN
  }
}

export const fieldBooleanOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

export const tasksCompletedCount = (goals) => {
  return goals?.reduce((totalCount, goal) => {
    if (goal.plan && goal.plan.milestones) {
      return (
        totalCount +
        goal.plan.milestones.reduce((milestoneCount, milestone) => {
          if (milestone.tasks) {
            const doneCount = milestone.tasks.filter((task) => task.status === 'DONE').length
            return milestoneCount + doneCount
          }
          return milestoneCount
        }, 0)
      )
    }
    return totalCount
  }, 0)
}

export const calculateProgress = (tasks: UserTask[]) => {
  let totalTasks = 0
  let completedTasks = 0
  if (tasks) {
    for (const task of tasks) {
      totalTasks++
      if (task.status === 'DONE') {
        completedTasks++
      }
    }

    const progress = totalTasks === 0 ? 0 : Math.round((completedTasks / totalTasks) * 100)
    return progress
  } else return 0
}

export const allTasksTodo = (plan) => {
  if (plan) {
    for (const milestone of plan.milestones) {
      for (const task of milestone.tasks) {
        if (task.status !== 'TODO') {
          return false
        }
      }
    }
    return true
  }
  return true
}

export const calculateTasksProgress = (tasks) => {
  if (tasks?.length > 0) {
    const totalTasks = tasks?.length
    let statusCounts = {
      DONE: 0,
      TODO: 0,
      IN_PROGRESS: 0,
    }

    tasks.forEach((task) => {
      if (task.status in statusCounts) {
        statusCounts[task.status]++
      }
    })
    if (statusCounts.DONE === totalTasks) return 100
    if (statusCounts.TODO === totalTasks) return 0

    const progress = (statusCounts.IN_PROGRESS / totalTasks) * 100
    return Math.round(progress)
  } else return 0
}

export const getLatestEndDate = (tasks) => {
  if (tasks?.length === 0) return ''

  const latestDate = tasks?.reduce((latest, task) => {
    const endDate = moment(task?.expectedEndDate)
    return endDate.isAfter(latest) ? endDate : latest
  }, moment(0))

  return latestDate?.format('MMMM Do, YYYY')
}

const goalPriorityBadgeColors: Record<BusinessGoalPriority, BadgeColor> = {
  LOW_PRIORITY: 'Green',
  MEDIUM_PRIORITY: 'Orange',
  HIGH_PRIORITY: 'Red',
}

const goalCategoryBadgeColors: Record<BusinessGoalCategory, BadgeColor> = {
  MARKETING: 'Green',
  HR: 'Orange',
  SALES: 'Red',
  LEGAL: 'Grey',
  COMMUNICATIONS: 'Primary',
  CUSTOMER_SERVICE: 'Primary',
  FINANCE: 'Red',
  INFORMATION_TECHNOLOGY: 'Red',
  OPERATIONS: 'Primary',
  PRODUCTION: 'Grey',
  RESEARCH_AND_DEVELOPMENT: 'Orange',
  STRATEGY: 'White',
}

// Function to get colors for goal category
export const getColorForGoalCategory = (goalCategory: BusinessGoalCategory): BadgeColor => {
  return goalCategoryBadgeColors[goalCategory] || 'Grey'
}

// Function to get colors for goal priority
export const getColorForGoalPriority = (goalCategory: BusinessGoalPriority): BadgeColor => {
  return goalPriorityBadgeColors[goalCategory] || 'Grey'
}

export const formatMobileNumber = (mobileNumber: string) => {
  if (!mobileNumber) return ''
  const phoneNumber = parsePhoneNumberFromString(mobileNumber)
  if (!phoneNumber) {
    return ''
  }

  return phoneNumber.formatInternational()
}

// Calculate the number of working days (Monday to Friday) between two dates, inclusive of startDate and endDate
export const calculateWorkingDaysBetweenTwoDates = (startDate: Moment, endDate: Moment): number => {
  let count = 0
  let current = startDate.clone()

  while (!current.isAfter(endDate, 'day')) {
    const dayOfWeek = current.day()
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count += 1 // Exclude weekends
    current.add(1, 'day')
  }
  return count
}

// Calculate total estimated hours for allTasks with start dates after the expected end date
export const calculateTotalEstimationHoursOfUserTasks = (
  allTasks: UserTask[],
  expectedEndDateofCurrentTask: Moment,
  taskId: string
): number => {
  return allTasks.reduce((sum, task) => {
    // Ignore tasks with the specified taskId
    if (task.taskId === taskId || task.status === UserTaskItemStatus.Done) {
      return sum
    }
    const taskStartDate = moment(task.startDate)
    if (taskStartDate.isAfter(expectedEndDateofCurrentTask, 'day')) {
      return sum + (task.estimationHour || 0)
    }
    return sum
  }, 0)
}

// Checks if a given date lies within the current week and if it is in the past
export const isDateInCurrentWeekAndPast = (date: string): boolean => {
  const targetDate = moment(date)
  const startOfWeek = moment().startOf('week')
  const endOfWeek = moment().endOf('week')
  const isInCurrentWeek = targetDate.isBetween(startOfWeek, endOfWeek, 'day', '[]')
  const isInPast = targetDate.isBefore(moment(), 'day')
  return isInCurrentWeek || isInPast
}

export const calculateNewPlanEndDate = (planExpectedEndDate, hoursToBeAddedd, hoursSpentPerDay) => {
  let extraDaysRequired = Math.ceil(Math.abs(hoursToBeAddedd) / hoursSpentPerDay)
  // Start with the preliminary new end date
  let newPlanEndDate = moment(planExpectedEndDate)
  // Loop through each additional day, checking for weekends
  while (extraDaysRequired > 0) {
    newPlanEndDate = newPlanEndDate.add(1, 'days')
    // If the day is a weekday, reduce the extra days counter
    if (newPlanEndDate.day() !== 6 && newPlanEndDate.day() !== 0) {
      extraDaysRequired -= 1
    }
  }
  return newPlanEndDate.format('YYYY-MM-DD')
}

export const createWeeklyMilestones = (tasks: UserTask[]) => {
  // Sort tasks by start date using moment
  tasks.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))

  const milestones: BusinessGoalMilestone[] = []
  let currentWeekStart
  let weekCount = 1

  for (let task of tasks) {
    const taskStartDate = moment(task.startDate)

    // Check if we need to create a new milestone
    if (!currentWeekStart || taskStartDate.isAfter(moment(currentWeekStart).add(6, 'days'))) {
      // Create new milestone
      currentWeekStart = task.startDate
      const weekEndDate = taskStartDate.clone().add(6, 'days') // End date is 6 days after start date

      milestones.push({
        name: `Week ${weekCount++}`,
        startDate: currentWeekStart,
        expectedEndDate: weekEndDate.format('YYYY-MM-DD'),
        tasks: [],
      })
    }

    const currentMilestone = milestones[milestones.length - 1]
    if (
      taskStartDate.isBetween(
        currentMilestone.startDate,
        currentMilestone.expectedEndDate,
        null,
        '[]'
      )
    ) {
      currentMilestone?.tasks?.push(task)
    }
  }

  return milestones
}
export const removeBusinessSocialMediaFieldsLinks = (businessProfile: BusinessProfile) => {
  businessProfile.facebook = businessProfile?.facebook?.replace('www.facebook.com/', '')
  businessProfile.instagram = businessProfile?.instagram?.replace('www.instagram.com/', '')
  businessProfile.linkedin = businessProfile?.linkedin?.replace('www.linkedin.com/in/', '')
  businessProfile.tiktok = businessProfile?.tiktok?.replace('www.tiktok.com/', '')
  businessProfile.twitter = businessProfile?.twitter?.replace('www.twitter.com/', '')
  businessProfile.youtube = businessProfile?.youtube?.replace('www.youtube.com/', '')

  return businessProfile
}
export const addBusinessSocialMediaFieldsLinks = (businessProfile: BusinessProfile) => {
  businessProfile.facebook = businessProfile?.facebook
    ? `www.facebook.com/${businessProfile?.facebook}`
    : ''
  businessProfile.instagram = businessProfile?.instagram
    ? `www.instagram.com/${businessProfile?.instagram}`
    : ''
  businessProfile.linkedin = businessProfile?.linkedin
    ? `www.linkedin.com/in/${businessProfile?.linkedin}`
    : ''
  businessProfile.tiktok = businessProfile?.tiktok
    ? `www.tiktok.com/${businessProfile?.tiktok}`
    : ''
  businessProfile.twitter = businessProfile?.twitter
    ? `www.twitter.com/${businessProfile?.twitter}`
    : ''
  businessProfile.youtube = businessProfile?.youtube
    ? `www.youtube.com/${businessProfile?.youtube}`
    : ''

  return businessProfile
}
