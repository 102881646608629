import React, { useEffect } from 'react'
import { FIELD_NAME, LABEL } from 'config'
import { AppFC, IOptionsItem } from 'types'
import InputField from 'components/Common/InputField'
import SelectField from 'components/Common/SelectField'
import { calculateYearMonths, getMonthAndYear, isFutureDate, yearOptions } from 'utils/helper'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReturn,
  UseFormTrigger,
} from 'react-hook-form'
import {
  BUSINESS_STRUCTURE,
  monthOptions,
  registeredBusinessStructure,
  resetFields,
} from 'components/MonthlyAttestation/utils'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { RootState } from 'App'
import moment from 'moment-timezone'

interface IRegisterBusinessFields {
  year: string
  month: string
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: UseFormReturn['setValue']
  trigger: UseFormTrigger<FieldValues>
  registerBusinessStructureOptions: Array<IOptionsItem>
  watch: (name: string) => FieldValues[keyof FieldValues]
  register?: UseFormRegister<FieldValues>
}
const RegisterBusinessFields: AppFC<IRegisterBusinessFields> = ({
  errors,
  year,
  month,
  register = () => {},
  watch,
  control,
  setValue,
  trigger,
  registerBusinessStructureOptions,
}) => {
  const userId = useSelector((state: RootState) => state.user.user.id)
  const isV2FeatureFlagOrgEnabled = useFeatureFlag(userId, 'release-v2-org')
  const isV2FeatureFlagUserEnabled = useFeatureFlag(userId, 'release-v2-user')
  const isV2FlagEnabled = isV2FeatureFlagOrgEnabled && isV2FeatureFlagUserEnabled
  const registeredBusinessValue = watch(BUSINESS_STRUCTURE.REGISTER_BUSINESS_OPTION_ID)

  const yearEstablished = watch('yearEstablished')
  useEffect(() => {
    if (isV2FlagEnabled) {
      setValue('month', moment(yearEstablished).date)
      setValue('year', moment(yearEstablished).date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isV2FlagEnabled, registeredBusinessValue, yearEstablished])
  return (
    <>
      <Controller
        name={FIELD_NAME.REGISTER_BUSINESS_STRUCTURE_OPTION_ID}
        control={control}
        render={({ field }) => (
          <SelectField
            {...field}
            classes={'mt-4'}
            label={LABEL.REGISTER_BUSINESS_STRUCTURE}
            options={registerBusinessStructureOptions?.filter((obj) =>
              obj.industry.includes('attestation')
            )}
            onChange={(e) => {
              if (e === registeredBusinessStructure.noBusinessOptionId) {
                resetFields(setValue)
              }
              field.onChange(e)
              trigger(FIELD_NAME.MONTH)
              trigger(FIELD_NAME.YEAR)
            }}
            placeholder={`Select ${LABEL.REGISTER_BUSINESS_STRUCTURE}`}
            errors={errors[FIELD_NAME.REGISTER_BUSINESS_STRUCTURE_OPTION_ID]?.message || ''}
          />
        )}
      />
      {!isV2FlagEnabled ? (
        <div className="grid grid-cols-12 gap-2 mt-3">
          <div className="col-span-6">
            <Controller
              name={FIELD_NAME.MONTH}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  label={LABEL.MONTH}
                  errors={errors?.month || isFutureDate(month, year)}
                  options={monthOptions}
                  disable={
                    registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
                    registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
                    !registeredBusinessValue
                  }
                  placeholder="Select"
                />
              )}
            />
          </div>
          <div className="col-span-6">
            <Controller
              name={FIELD_NAME.YEAR}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  label={LABEL.YEAR}
                  options={yearOptions()}
                  errors={errors?.year}
                  disable={
                    registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
                    registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
                    !registeredBusinessValue
                  }
                  placeholder="Select"
                />
              )}
            />
          </div>
        </div>
      ) : (
        <InputField
          type={
            registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
            registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
            !registeredBusinessValue
              ? 'text'
              : 'date'
          }
          label={`Year Established`}
          errors={errors.yearEstablished}
          {...register('yearEstablished')}
          placeholder="Not Yet"
          disabled={
            registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
            registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
            !registeredBusinessValue
          }
          className="mt-3"
        />
      )}
      <InputField
        className="mt-3"
        type={'text'}
        label={LABEL.YEARS_IN_BUSINESS}
        value={
          isV2FlagEnabled
            ? calculateYearMonths(
                getMonthAndYear(yearEstablished).month,
                getMonthAndYear(yearEstablished).year
              )
            : calculateYearMonths(month, year)
        }
        disabled={true}
      />
    </>
  )
}
export default RegisterBusinessFields
